import React, { useState, useEffect, useContext } from "react"
import FirebaseProvider, {
    FirebaseContext,
} from "../components/idealline/Firebase"
import ProfileThumb from "../components/idealline/ProfileThumb"
import { ClientOnly, Layout } from "../components/common"
import "firebase/firestore"
import moment from "moment"
import Button from "../components/common/Button"
import LinesList from "../components/idealline/List"
import TrackCard from "../components/tracks/TrackCard"

const Favorites = (props) => {
    const auth = useContext(FirebaseContext)
    const firebase = auth.app
    const tracksRef = firebase.firestore().collection(`tracks`)

    const [favs, setFavs] = useState([])

    useEffect(() => {
        const loadFavs = async () => {
            const tracksRes = await tracksRef.get()
            let tracks = tracksRes.docs.map(doc => {
                return { id: doc.id, ...doc.data() }
            })
            setFavs(tracks)
        }

        loadFavs()
    }, [props])

    return (
        <div className="my-10">
            <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8 2xl:gap-10 text-gray-600 mb-6">
                {favs?.map((t) => (
                    <>
                        <div className="p-6 bg-gray-100 rounded-lg">
                            <div className="flex items-center mb-4">
                                TrackId: {t.id}
                            </div>
                            {t.favorites?.map((f) => (
                                <div className="text-xs text-left whitespace-nowrap space-y-2 p-2 border rounded-xl my-2">
                                    <div>
                                        <span className="text-indigo-500">
                                            {f.created
                                                .toDate()
                                                .toLocaleString()}
                                        </span>
                                    </div>
                                    <div>
                                        <ProfileThumb
                                            key={{ uid: f.uid, t }}
                                            uid={f.uid}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                ))}
            </section>
        </div>
    )
}


const Admin = (props) => (
    <Layout>
        <ClientOnly>
            <FirebaseProvider>
                <h1>Admin Panel</h1>
                <h2 className="mb-6">Favorites by Track</h2>
                <Favorites />
                <h2 className="mb-6">Newest Racing Lines (last 50)</h2>
                <LinesList
                    publicLinesOnly={false}
                    closedLinesOnly={false}
                    limit={50}
                    oneRacingLinePerTrack={false}
                />
            </FirebaseProvider>
        </ClientOnly>
    </Layout>
)
export default Admin
